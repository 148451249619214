import { isMobile } from 'react-device-detect';
export const strains = [
  {
    title: "Afghani Lemons",
    cross: "(Afghan Kush x Super Lemon Haze)",
    title2: "Feminized Photoperiod",
    description:
      "Relief of Chronic Muscle aches, Anxiety, Depression, Fatigue, Stimulates Creativity and Arousal",
    thcLevels: "thc: 24-26%",
    yield: "Average Yield -- 3.5 to 4 oz in 5-gallon under 100 watt LED",
    sativaVSindica: "70% Sativa",
    flowerTime: "flower time: 11-12 weeks",
    images: [
      "./assets/Afghani_Lemons/afghani-lemons-0.jpeg",
      "./assets/Afghani_Lemons/afghani-lemons-1.jpeg",
      "./assets/Afghani_Lemons/afghani-lemons-2.jpeg",
      "./assets/Afghani_Lemons/afghani-lemons-3.jpeg",
      "./assets/Afghani_Lemons/afghani-lemons-4.jpeg",
      "./assets/Afghani_Lemons/afghani-lemons-5.jpeg",
    ],
  },

  {
    title: "Shield Maiden",
    cross: "(Charlotte's Web x Yummies)",
    title2: "Feminized Photoperiod",
    description: "1:1 THC:CBD Terpene Healing Machine ",
    thcLevels: "thc: 22-24%",
    yield: "Average Yield -- 3.5 to 4 oz in 5-gallon under 100 watt LED",
    sativaVSindica: "80% Sativa",
    flowerTime: "flower time: 9-10 weeks",
    images: [
      "./assets/Shield_Maiden/shield-maiden-0.jpeg",
      "./assets/Shield_Maiden/shield-maiden-1.jpeg",
      "./assets/Shield_Maiden/shield-maiden-2.jpeg",
      "./assets/Shield_Maiden/shield-maiden-3.jpeg",
      "./assets/Shield_Maiden/shield-maiden-4.jpeg",
      "./assets/Shield_Maiden/shield-maiden-5.jpeg",
      "./assets/Shield_Maiden/shield-maiden-6.jpeg",
      "./assets/Shield_Maiden/shield-maiden-7.jpeg",
    ],
  },
  
 
  {
    title: "Tropical Skunk",
    cross: "(Island Sweet Skunk x GDP)",

    title2: "Feminized Photoperiod",
    description:
      "Daytime sativa-dominant strain with medicinal qualities known to alleviate muscle pain, depression, anxiety & stress.",
    thcLevels: "thc: 22-25%",
    yield: "Heavy yield -- 3-4 oz per 5-gallon pot under 100watt LED",
    sativaVSindica: "50-50 Indica/Sativa",
    flowerTime: "flower time: 10-12 weeks",
    images: ["./assets/General/seed.jpg"],
  },
  {
    title: "Alaskan Shield Maiden",
    cross: "(ATF x Shield Maiden)",

    title2: "Feminized Photoperiod",
    description:
      "Works well for mood enhancement, PTSD, Depression, Anxiety, energy booster",
    thcLevels: "thc: 22-24% cbd: 12%",
    sativaVSindica: "90/10 Sativa",
    flowerTime: "flower time: 11 weeks",
    images: ["./assets/General/seed.jpg"],
  },


  {
    title: "Tropical Thunder",
    cross: "(Tropical Skunk x ATF)",

    title2: "Feminized Photoperiod",
    description: "Perfect for outdoor growers in dry arid climates.",
    thcLevels: "thc: 20-22%",
    sativaVSindica: "100% ativa",
    flowerTime: "flower time: 11 weeks",
    images: ["./assets/General/seed.jpg"]
  },
  // {
  //   title: "Yummies Hybrid",
  //   cross: "(Sour Diesal x Sage Diesal)",
  //   title2: "Feminized Photoperiod",
  //   description:
  //     "A heavy-hitting strain very close to Chubby's heart that was created specifically to alleviate the pains and aches associated with Fibromyalgia that is dear to Chubby's heart.",
  //   thcLevels: "thc: 24-28%",
  //   yield: "Heavy yield -- 3-5 oz in 5-gallon under 100 watt LED",
  //   sativaVSindica: "Sativa Dominant Hybrid",
  //   flowerTime: "flower time: 9-10 weeks",
  //   images: [
  //     "./assets/Yummies/yummies-0.jpeg",
  //     "./assets/Yummies/yummies-1.jpeg",
  //     "./assets/Yummies/yummies-2.jpeg",
  //     "./assets/Yummies/yummies-3.jpeg",
  //     "./assets/Yummies/yummies-4.jpeg",
  //     "./assets/Yummies/yummies-5.jpeg",
  //     "./assets/Yummies/yummies-6.jpeg",
  //   ],
  // },
  {
    title: "Granddaddy Purple",
    title2: "Feminized Photoperiod",
    description:
      "GDP is a famous indica cross of Mendo Purps, Skunk, and Afghanistan, frequently containing 25-28% THC!!!",
    thcLevels: "thc: 25-28%",
    yield: "Heavy yield -- 3oz in 5-gallon under 100 watt LED",
    sativaVSindica: "80% Indica 20% Sativa",
    flowerTime: "flower time: 8 weeks",
    images: [
      "./assets/GDP/gdp-0.jpeg",
      "./assets/GDP/gdp-1.jpeg",
      "./assets/GDP/gdp-3.jpeg",
      "./assets/GDP/gdp-3.jpeg",
      "./assets/GDP/gdp-4.jpeg",
      "./assets/GDP/gdp-5.jpeg",
      "./assets/GDP/gdp-6.jpeg",
      "./assets/GDP/gdp-7.jpeg",
      "./assets/GDP/gdp-8.jpeg",
      "./assets/GDP/gdp-9.jpeg",
      "./assets/GDP/gdp-10.jpeg",
      "./assets/GDP/gdp-11.jpeg",
      "./assets/GDP/gdp-12.jpeg",
      "./assets/GDP/gdp-13.jpeg",
      "./assets/GDP/gdp-0.jpeg",
      "./assets/GDP/gdp-14.jpeg",
      "./assets/GDP/gdp-15.jpeg",
      "./assets/GDP/gdp-16.jpeg",
      "./assets/GDP/gdp-17.jpeg",
      "./assets/GDP/gdp-18.jpeg",
      "./assets/GDP/gdp-19.jpeg",
    ],
  },

  {
    title: "King Gelato",
    cross: "(Gelato x GG#4)",

    title2: "feminized auto",
    description:
      "Amazing Terpene profile that will fill the room with fruity aroma at the crack of a nug. Mellow, relaxing effects, with a heavy yield!",
    thcLevels: "thc: 23-26%",
    yield: "Heavy Yield -- 3oz in a 5-gallon under 100 watt LED",
    sativaVSindica: "85-90% Sativa",
    flowerTime: "110 days seed to harvest",
    images: [
      "./assets/Purple_Gelato/purple-gelato-0.jpeg",
      "./assets/Purple_Gelato/purple-gelato-1.jpeg",
      "./assets/Purple_Gelato/purple-gelato-2.jpeg",
      "./assets/Purple_Gelato/purple-gelato-3.jpeg",
      "./assets/Purple_Gelato/purple-gelato-4.jpeg",
    ],
  },

  
  // {
  //   title: "SkyWalker",
  //   cross: "(Skywalker OG x Ruderalis)",

  //   title2: "feminized auto",
  //   description: "SkyWalker Auto is our highest yielding auto flower!",
  //   thcLevels: "thc: 22-25%",
  //   yield: "Heavy Yield -- approx 4oz in 5-gallon under 100 watt LED",
  //   sativaVSindica: "Sativa Dominant Hybrid",
  //   flowerTime: "100 days seed to harvest",
  //   images: [
  //     "./assets/Skywalker/skywalker-2.jpeg",
  //     "./assets/Skywalker/skywalker-1.jpeg",
  //     "./assets/Skywalker/skywalker-2.jpeg",
  //     "./assets/Skywalker/skywalker-3.jpeg",
  //     "./assets/Skywalker/skywalker-4.jpeg",
  //     "./assets/Skywalker/skywalker-5.jpeg",
  //     "./assets/Skywalker/skywalker-6.jpeg",
  //     "./assets/Skywalker/skywalker-7.jpeg",
  //     "./assets/Skywalker/skywalker-8.jpeg",
  //     "./assets/Skywalker/skywalker-9.jpeg",
  //   ],
  // },
  {
    title: "King Glue",
    cross: "(King Gelato X GG#4)",
    title2: "feminized auto",
    description: "",
    thcLevels: "thc: 24-26%",
    yield: "Average Yield -- approx 2-2.5oz in 5-gallon under 100 watt LED",
    sativaVSindica: "90% Indica",
    flowerTime: "100 days seed to harvest",
    images: [
      "./assets/King_Glue/king-glue-0.jpeg",
      "./assets/King_Glue/king-glue-1.jpeg",
      "./assets/King_Glue/king-glue-3.jpeg",
      "./assets/King_Glue/king-glue-3.jpeg",
      "./assets/King_Glue/king-glue-4.jpeg",
    ],
  },
  {
    title: "Blue Gelato",
    cross: "(Blue Dream X Gelato)",
    title2: "feminized auto",
    thcLevels: "thc: 23-25%",
    yield:
      "Average yielder who does better for outdoor grows. -- Averages 2 oz indoor with 5 gallon pot 100w led lights",
    sativaVSindica: "Sativa dominant ",
    flowerTime: "100-110 total grow days",
    images: [
      "./assets/Blue_Gelato/blue-gelato-0.jpeg",
      "./assets/Blue_Gelato/blue-gelato-1.jpeg",
      "./assets/Blue_Gelato/blue-gelato-2.jpeg",
    ],
  },
  {
    title: "Lemon Haze",
    cross: "(Super Lemon Haze x Ruderalis)",
    title2: "feminized auto",
    thcLevels: "thc: 19-23%",
    yield: "2 oz indoor with 5 gallon pot under 100 watt led ",
    sativaVSindica: "Sativa dominant 80/20",
    flowerTime: "90 day total grow time ",
    images: [
      "./assets/Lemon_Haze/lemon-haze-0.jpeg",
      "./assets/Lemon_Haze/lemon-haze-2.jpeg",
      "./assets/Lemon_Haze/lemon-haze-1.jpeg",
    ],
  },
  {
    title: "Strawberry Cheesecake",
    cross: "(Strawberry Kush x Cheesecake)",

    title2: "feminized auto",
    thcLevels: "thc: 22-24%",
    yield: "2.5-3oz per 5 gallon under 100 watt led light",
    sativaVSindica: "Indica dominant 70/30",
    flowerTime: "80 days grow time",
    images: [
      "./assets/Strawberry_Cheesecake/strawberry-cheesecake-0.jpeg",
      "./assets/Strawberry_Cheesecake/strawberry-cheesecake-1.jpeg",
      "./assets/Strawberry_Cheesecake/strawberry-cheesecake-2.jpeg",
      "./assets/Strawberry_Cheesecake/strawberry-cheesecake-3.jpeg",
    ],
  },
  {
    title: "Wedding Cheesecake",
    cross: "(Wedding Cake X Cheesecake)",
    title2: "feminized auto",
    thcLevels: "thc: 25-27%",
    yield: "3.5 oz per 5 gallon pot under 100w led light",
    sativaVSindica: "Hybrid 65/35 sativa ",
    flowerTime: "90 day grow time",
    images: [
      "./assets/Wedding_Cheesecake/wedding-cheesecake-1.jpeg",
      "./assets/Wedding_Cheesecake/wedding-cheesecake-1.jpeg",
      "./assets/Wedding_Cheesecake/wedding-cheesecake-4.jpeg",
      "./assets/Wedding_Cheesecake/wedding-cheesecake-3.jpeg",
      "./assets/Wedding_Cheesecake/wedding-cheesecake-2.jpeg",
    ],
  },
  {
    title: "Runtz",
    cross: "(Runtz x Ruderalis)",

    title2: "feminized auto",
    thcLevels: "thc: 26-29%",
    yield: "3.5-4 oz per 5gallon pot under 100watt led lights. ",
    sativaVSindica: "50/50 hybrid",
    flowerTime: "75-80 days total grow time ",
    images: [
      "./assets/Runtz/runtz-0.jpeg",
      "./assets/Runtz/runtz-1.jpeg",
      "./assets/Runtz/runtz-2.jpeg",
      "./assets/Runtz/runtz-3.jpeg",
      "./assets/Runtz/runtz-4.jpeg",
    ],
  },
  {
    title: "Blue Dream",
    title2: "feminized auto",
    cross: "(Blue Dream x Ruderalis)",

    thcLevels: "thc: 25-27%",
    yield: "3.5 oz per 5 gallon under 100w led light",
    sativaVSindica: "Sativa dominant 80/20",
    flowerTime: "90 day total grow time",
    images: [
      "./assets/Blue_Dream/blue-dream-0.jpeg",
      "./assets/Blue_Dream/blue-dream-1.jpeg",
      "./assets/Blue_Dream/blue-dream-2.jpeg",
      "./assets/Blue_Dream/blue-dream-3.jpeg",
    ],
  },
  
  {
    title: "Blue Lemonade Punch",
    title2: "feminized auto",
    cross: "(Blue Dream x Punchout)",

    thcLevels: "thc: 24-26%",
    yield: null,
    sativaVSindica: "70/30 Sativa",
    flowerTime: "80 days total grow time",
    images: [
     './assets/General/seed.jpg'
    ],
  },
  {
    title: "U.K. Cheese",
    title2: "feminized auto",
    cross: null,

    thcLevels: "thc: 22-24%",
    yield: null,
    sativaVSindica: "75/25 Indica",
    flowerTime: "80 days total grow time",
    images: [
     './assets/General/seed.jpg'
    ],
  },
  {
    title: "Apple Fritter",
    title2: "feminized auto",
    cross: null,

    thcLevels: "thc: 22-24%",
    yield: null,
    sativaVSindica: "50/50 Hybrid",
    flowerTime: "75 days total grow time",
    images: [
     './assets/General/seed.jpg'
    ],
  },
  {
    title: "Apple Fritter #3",
    title2: "feminized auto",
    cross: null,

    thcLevels: "thc: 22-24%",
    yield: null,
    sativaVSindica: "50/50 Hybrid",
    flowerTime: "90 days total grow time",
    images: [
     './assets/General/seed.jpg'
    ],
  },
  {
    title: "Blu Cheese",
    title2: "feminized auto",
    cross: null,

    thcLevels: "thc: 22-24%",
    yield: null,
    sativaVSindica: "50/50 Hybrid",
    flowerTime: "80 days total grow time",
    images: [
     './assets/General/seed.jpg'
    ],
  },
  // {
  //   title: "Blu Cheese",
  //   cross: "(Blue Dream x UK Cheese)",

  //   strainClass: "Feminized Autoflower",
  //   displayPrice: "$8.00 per seed",
  //   price: 8,
  //   id: 76,

  //   qty: 0,
  //   features: [
  //     "22-24% THC",
  //     "50/50 Hybrid ",
  //     "80 days seed to harvest",
  //   ],
  //   image: "./assets/General/seed.jpg",
  // },
  {
    title: "Strawberry Apple Fritter",
    title2: "feminized auto",
    cross: "(Strawberry Cheesecake x Apple Fritter)",

    thcLevels: "thc: 23-25%",
    yield: null,
    sativaVSindica: "60/40 Indica",
    flowerTime: "75 days total grow time",
    images: [
     './assets/General/seed.jpg'
    ],
  },
  {
    title: "Sour Apple Fritter",
    title2: "feminized auto",
    cross: "(Sour Diesal x Apple Fritter)",

    thcLevels: "thc: 22-24%",
    yield: null,
    sativaVSindica: "65/35 Indica",
    flowerTime: "80 days total grow time",
    images: [
     './assets/General/seed.jpg'
    ],
  },

  {
    title: "Amnesia Haze",
    cross: null,
    title2: "regular photo",
    thcLevels: "thc: 20-22% 1% CBD",
    yield: "Moderate Yield",
    sativaVSindica: "Sativa dominant 80/20",
    flowerTime: "11 weeks flower time",
    images: ["./assets/General/seed.jpg"],
  },

  {
    title: "Blueberry Kush",
    cross: null,
    flowerTime: "10 weeks flower time",
    title2: "regular photo",
    thcLevels: "thc: 24%",
    sativaVSindica: "100% Indica",
    images: ["./assets/General/seed.jpg"],
  },
  

  {
    title: "Green Crack",
    cross: null,
    flowerTime: "8 weeks flower time",
    title2: "regular photo",
    thcLevels: "thc: 22-25%",
    sativaVSindica: "Sativa dominant 65/35",
    images: ["./assets/General/seed.jpg"],
  },
  {
    title: "OG Kush #23",
    cross: null,
    flowerTime: "9 weeks flower time",
    title2: "feminized photo",
    thcLevels: "thc: 24-26%  1% CBN",

    sativaVSindica: "Sativa dominant 60/40",
    images: ["./assets/General/seed.jpg"],
  },
  {
    title: "Skywalker OG",
    cross: null,
    title2: "regular photo",
    thcLevels: "thc: 24-25% 2% CBD ",
    flowerTime: "9 weeks flower time",
    sativaVSindica: "Indica dominant 85/15",
    images: ["./assets/General/seed.jpg"],
  },
  {
    title: "Purple Punch",
    cross: null,
    title2: "regular photo",
    subTitle: null,
    thcLevels: "thc: 18-20% 1% CBD ",
    flowerTime: "9 weeks flower time",
    sativaVSindica: "Indica dominant 80/20",
    images: ["./assets/General/seed.jpg"],
  },
  // {
  //   title: "Super Silver Haze",
  //   cross: null,
  //   title2: "regular photo",
  //   subTitle: null,
  //   thcLevels: "thc: 18-22% 1% CBD ",
  //   flowerTime: "11 weeks flower time",
  //   sativaVSindica: "Sativa dominant 80/20",
  //   images: ["./assets/General/seed.jpg"],
  // },
  {
    title: "Burnt Marshmallow",
    title2: "regular photo",
    cross: "(Black Skunk x Stay Puft)",
    thcLevels: "thc: 28%",
    flowerTime: "10 weeks flower time",
    sativaVSindica: "Indica dominant 70/30",
    images: ["./assets/General/seed.jpg"],
  },

  // {
  //   title: "Granddaddy Skunk",
  //   title2: "regular photo",
  //   cross: "(Grandaddy Urkle x Black Skunk)",
  //   thcLevels: "thc: 22%",
  //   flowerTime: "10 weeks flower time",
  //   sativaVSindica: "Indica dominant 75/25",
  //   images: ["./assets/General/seed.jpg"],
  // },
  {
    title: "Hindu Kush",
    title2: "regular photo",
    cross: null,
    thcLevels: "thc: 25-27%",
    flowerTime: "8 weeks flower time",
    sativaVSindica: "100% Indica",
    images: ["./assets/General/seed.jpg"],
  },
  {
    title: "Strawberry Cough",
    title2: "regular photo",
    cross: null,
    thcLevels: "thc: 22-24%",
    flowerTime: "11 weeks flower time",
    sativaVSindica: "80/20 Sativa",
    images: ["./assets/General/seed.jpg"],
  },
  
  {
    title: "Northern Lights",
    title2: "regular photo",
    cross: null,
    thcLevels: "thc: 18-22%",
    flowerTime: "8 weeks flower time",
    sativaVSindica: "100% Indica",
    images: ["./assets/General/seed.jpg"],
  },
  {
    title: "Black Shield",
    title2: "regular photo",
    cross: "(Black Skunk x Shield Maiden)",
    thcLevels: "thc: 24% cbd: 20%",
    flowerTime: "10 weeks flower time",
    sativaVSindica: "60/40 Indica",
    images: ["./assets/General/seed.jpg"],
  },
  {
    title: "Black Kush",
    title2: "regular photo",
    cross: "(Black Skunk x OG Kush)",
    thcLevels: "thc: 22-24% cbn: 1%",
    flowerTime: "10 weeks flower time",
    sativaVSindica: "60/40 Indica",
    images: ["./assets/General/seed.jpg"],
  },
  {
    title: "Colombian Gold",
    title2: "regular photo",
    cross: null,
    thcLevels: "thc: 19%",
    flowerTime: "12 weeks flower time",
    sativaVSindica: "75/25 Sativa",
    images: ["./assets/General/seed.jpg"],
  },
  {
    title: "Black Rifle",
    title2: "regular photo",
    cross: "(Black Skunk x AK-47)",
    thcLevels: "thc: 22-23%",
    flowerTime: "9 weeeks flower time",
    sativaVSindica: "50/50 Hybrid",
    images: ["./assets/General/seed.jpg"],
  },
 
  {
    title: "Mayan Calendar",
    title2: "regular photo",
    cross: null,
    thcLevels: "thc: 25%",
    flowerTime: "13 weeks flower time",
    sativaVSindica: "90/10 Sativa",
    images: ["./assets/General/seed.jpg"],
  },
  
  {
    title: "Lambs Breath",
    title2: "regular photo",
    subTitle: "aka Lambs Bread",
    thcLevels: "thc: 22%",
    flowerTime: "10 weeks flower time",
    sativaVSindica: "70/30 Sativa",
    images: ["./assets/General/seed.jpg"],
  },
  {
    title: "Granddaddy Purple",
    title2: "regular photo",
    cross: null,
    thcLevels: "thc: 25-27%",
    flowerTime: "9 weeks flower time",
    sativaVSindica: "70/30 Indica",
    images: ["./assets/General/seed.jpg"],
  },
  {
    title: "Alaskan Thunder Skunk",
    title2: "regular photo",
    cross: "(ATF x Black Skunk)",
    thcLevels: "thc: 22%",
    flowerTime: "10 weeks flower time",
    sativaVSindica: "60/40 Sativa",
    images: ["./assets/General/seed.jpg"],
  },
  {
    title: "Blueberry Kush",
    title2: "regular photo",
    cross: null,
    thcLevels: "thc: 24%",
    flowerTime: "10 weeks flower time",
    sativaVSindica: "100% Indica",
    images: ["./assets/General/seed.jpg"],
  },
  {
    title: "Grape Ape",
    title2: "regular photo",
    cross: null,
    thcLevels: "thc: 18-21%",
    flowerTime: "8 weeks flower time",
    sativaVSindica: "90/10 Indica",
    images: ["./assets/General/seed.jpg"],
  },
  // {
  //   title: "Afghan Kush",
  //   title2: "regular photo",
  //   cross: null,
  //   thcLevels: "thc: 21% cbd: 6% CBN: 1%",
  //   flowerTime: "8 weeks flower time",
  //   sativaVSindica: "100% Indica",
  //   images: ["./assets/General/seed.jpg"],
  // },
];
const soldOutStrains = [
  {
    title: "Granddaddy Urkle",
    cross: "(GDP x Purple Urkle)",

    title2: "Feminized Photoperiod",
    description:
      "Purple Urkle x Granddaddy Purple, making it an indica dominant. Tastes amazing and is great  for physical pain relief",
    thcLevels: "",
    yield: "Heavy yield -- 3-4 oz per 5-gallon pot under 100watt LED",
    sativaVSindica: "Indica dominant",
    flowerTime: "flower time: 9 weeks",
    images: [
      "./assets/GDU/gdu-7.jpeg",
      "./assets/GDU/gdu-1.jpeg",
      "./assets/GDU/gdu-5.jpeg",
      "./assets/GDU/gdu-3.jpeg",
      "./assets/GDU/gdu-4.jpeg",
      "./assets/GDU/gdu-5.jpeg",
      "./assets/GDU/gdu-6.jpeg",
      "./assets/GDU/gdu-0.jpeg",
      "./assets/GDU/gdu-8.jpeg",
      "./assets/GDU/gdu-9.jpeg",
      "./assets/GDU/gdu-10.jpeg",
    ],
  },
  {
    title: "Pineapple Express",
    title2: "regular photo",
    cross: "(ATF x Black Skunk)",
    thcLevels: "thc: 25%",
    flowerTime: "9 weeks flower time",
    sativaVSindica: "60/40 Sativa",
    images: ["./assets/General/seed.jpg"],
  },
  {
    title: "Maui Wowie",
    title2: "regular photo",
    cross: null,
    thcLevels: "thc: 26-28%",
    flowerTime: "11 weeks flower time",
    sativaVSindica: "80/20 Sativa",
    images: ["./assets/General/seed.jpg"],
  },
  {
    title: "Durban Poison",
    title2: "regular photo",
    cross: null,
    thcLevels: "thc: 24%",
    flowerTime: "11-12 weeks flower time",
    sativaVSindica: "100% Sativa",
    images: ["./assets/General/seed.jpg"],
  },
  {
    title: "Stay Puft",
    cross: "(Marshmallow OG x Grape Gas)",
    title2: "feminized photo",
    description:
      "An all time favorite here at Chubby’s! This is our only exotic strain, and she's a heavy indica",
    thcLevels: "thc: 30-32%",
    yield: "Average Yield -- 3.5 to 4 oz in 5-gallon under 100 watt LED",
    sativaVSindica: "70% Indica",
    flowerTime: "flower time: 8-9 weeks",
    images: [
      "./assets/Stay_Puft/stay-puft-0.jpeg",
      "./assets/Stay_Puft/stay-puft-1.jpeg",
      "./assets/Stay_Puft/stay-puft-2.jpeg",
      "./assets/Stay_Puft/stay-puft-3.jpeg",
      "./assets/Stay_Puft/stay-puft-4.jpeg",
      "./assets/Stay_Puft/stay-puft-5.jpeg",
      "./assets/Stay_Puft/stay-puft-6.jpeg",
      "./assets/Stay_Puft/stay-puft-7.jpeg",
      "./assets/Stay_Puft/stay-puft-8.jpeg",
      "./assets/Stay_Puft/stay-puft-9.jpeg",
      "./assets/Stay_Puft/stay-puft-10.jpeg",
      "./assets/Stay_Puft/stay-puft-11.jpeg",
      "./assets/Stay_Puft/stay-puft-12.jpeg",
      "./assets/Stay_Puft/stay-puft-13.jpeg",
      "./assets/Stay_Puft/stay-puft-14.jpeg",
    ],
  },
  {
    title: "Gorilla Glue #4",
    title2: "feminized auto",
    description:
      "Chubby's auto cut of this equally famous as it is potent hybrid marijuana strain that delivers heavy-handed euphoria and relaxation, leaving you feeling “glued” to the couch.",
    thcLevels: "thc: 23-26%",
    yield: "Modertate Yield -- 2-2.5 oz in 5-gallon under 100 watt LED",
    sativaVSindica: "",
    flowerTime: "100 days seed to harvest",
    images: [
      "./assets/GG4auto/gg4-1.jpeg",
      "./assets/GG4auto/gg4-2.jpeg",
      "./assets/GG4auto/gg4-3.jpeg",
      "./assets/GG4auto/gg4-4.jpeg",
      "./assets/GG4auto/gg4-5.jpeg",
      "./assets/GG4auto/gg4-6.jpeg",
      "./assets/GG4auto/gg4-1.jpeg",
      "./assets/GG4auto/gg4-7.jpeg",
      "./assets/GG4auto/gg4-8.jpeg",
      "./assets/GG4auto/gg4-9.jpeg",
      "./assets/GG4auto/gg4-10.jpeg",
      "./assets/GG4auto/gg4-11.jpeg",
      "./assets/GG4auto/gg4-12.jpeg",
      "./assets/GG4auto/gg4-13.jpeg",
      "./assets/GG4auto/gg4-14.jpeg",
    ],
  },
  {
    title: "Frozen Grapes",
    title2: "feminized photo",
    description:
      "One of our faves here at Chubby's! Heavy indica hybrid. Great for physical cramps and even insomnia.",
    thcLevels: "thc: 22-28%",
    yield: "Average Yielder",
    sativaVSindica: "70% Indica",
    flowerTime: "flower time: 8-9 weeks",
    images: [
      "./assets/Frozen_Grapes/frozen-grapes-0.jpeg",
      "./assets/Frozen_Grapes/frozen-grapes-.jpeg",
      "./assets/Frozen_Grapes/frozen-grapes-4.jpeg",
      "./assets/Frozen_Grapes/frozen-grapes-3.jpeg",
      "./assets/Frozen_Grapes/frozen-grapes-4.jpeg",
      "./assets/Frozen_Grapes/frozen-grapes-5.jpeg",
    ],
  },
  {
    title: "Purple Urkle",
    cross: "()",

    title2: "feminized photo",

    description:
      "Chubby's cut! Origins are believed to stem from a select phenotype of Mendocino Purps, while the essence is a blend of skunk, berry, and fresh grapes.",
    thcLevels: "thc: 18-22%",
    yield: "2.5 to 3oz in 5-gallon under 100watt LED",
    sativaVSindica: "70% Indica 30% Sativa",
    flowerTime: "flower time: 9 weeks",
    images: [
      "./assets/Purple_Urkle/purple-urkle-0.jpeg",
      "./assets/Purple_Urkle/purple-urkle-1.jpeg",
      "./assets/Purple_Urkle/purple-urkle-3.jpeg",
      "./assets/Purple_Urkle/purple-urkle-3.jpeg",
      "./assets/Purple_Urkle/purple-urkle-4.jpeg",
      "./assets/Purple_Urkle/purple-urkle-5.jpeg",
      "./assets/Purple_Urkle/purple-urkle-6.jpeg",
      "./assets/Purple_Urkle/purple-urkle-7.jpeg",
      "./assets/Purple_Urkle/purple-urkle-8.jpeg",
      "./assets/Purple_Urkle/purple-urkle-9.jpeg",
      "./assets/Purple_Urkle/purple-urkle-10.jpeg",
      "./assets/Purple_Urkle/purple-urkle-11.jpeg",
      "./assets/Purple_Urkle/purple-urkle-12.jpeg",
      "./assets/Purple_Urkle/purple-urkle-13.jpeg",
    ],
  },
  {
    title: "Stay Strong",
    cross: "(Stay Puft x Shield Maiden)",
    title2: "feminized photo",
    description: "2:1 THC:CBD Terpene Healing Machine ",
    thcLevels: "thc: 30-32%",
    yield: "Average Yield -- 3.5 to 4 oz in 5-gallon under 100 watt LED",
    sativaVSindica: "60% Indica 40% Sativa",
    flowerTime: "flower time: 9-10 weeks",
    images: [
      "./assets/Stay_Strong/stay-strong-0.jpeg",
      "./assets/Stay_Strong/stay-strong-1.jpeg",
      "./assets/Stay_Strong/stay-strong-2.jpeg",
      "./assets/Stay_Strong/stay-strong-3.jpeg",
    ],
  },
  {
    title: "Tropical Shield Maiden",
    cross: "(Tropical Skunk x Shield Maiden)",

    title2: "feminized photo",
    description:
      "Relief of Bi-Polar, Depression, Mood Swings, Loss of Appetite, Joint Pain, Headache, Migraine -- Not Recommended for those sensitive to high sativa strains",
    thcLevels: "24-26% THC 10-12% CBD",
    yield: "Average Yield -- 3.5 to 4 oz in 5-gallon under 100 watt LED",
    sativaVSindica: "70/30 Sativa",
    flowerTime: "flower time: 9-10 weeks",
    images: [
      "./assets/Tropical_Shield_Maiden/Tropical-shield-maiden-0.jpeg",
      "./assets/Tropical_Shield_Maiden/Tropical-shield-maiden-1.jpeg",
    ],
  },
  {
    title: "OG Kush",
    title2: "feminized photo",
    description:
      "Relief of Bi-Polar, Nausea, Loss of Appetite, Depression, Stress, Anxiety, Headache, Migraines",
    thcLevels: "24-26% THC",
    yield: "Average Yield -- 3.5 to 4 oz in 5-gallon under 100 watt LED",
    sativaVSindica: "60/40 Sativa",
    flowerTime: "flower time: 9-10 weeks",
    images: [
      "./assets/General/seed.jpeg",
    ],
  },
  {
    title: "Chem Dawg #4",
    cross: null,
    title2: "regular photo",
    subTitle: null,
    thcLevels: "thc: 20-23%",
    flowerTime: "11 weeks flower time",
    sativaVSindica: "50/50 Hybrid",
    images: ["./assets/General/seed.jpg"],
  },
  {
    title: "Granddaddy Diesal",
    cross: "(GDU x Shield Maiden)",

    title2: "Feminized Photoperiod",
    description:
      "Relief of Muscle Pain, Joint Inflammation, Gout, Multiple Sclerosis, Chronic migraines and Headaches ",
    thcLevels: "24-26% THC 15-16% CBD 1% CBN",
    yield: "Average Yield -- 3.5 to 4 oz in 5-gallon under 100 watt LED",
    sativaVSindica: "65/35 Sativa",
    flowerTime: "flower time: 9-10 weeks",
    images: [
      "./assets/Granddaddy_Diesal/granddaddy-diesal-0.jpeg",
      "./assets/Granddaddy_Diesal/granddaddy-diesal-1.jpeg",
      "./assets/Granddaddy_Diesal/granddaddy-diesal-2.jpeg",
      "./assets/Granddaddy_Diesal/granddaddy-diesal-3.jpeg",
      "./assets/Granddaddy_Diesal/granddaddy-diesal-4.jpeg",
      "./assets/Granddaddy_Diesal/granddaddy-diesal-5.jpeg",
      "./assets/Granddaddy_Diesal/granddaddy-diesal-6.jpeg",
      "./assets/Granddaddy_Diesal/granddaddy-diesal-7.jpeg",
    ],
  },
  {
    title: "Lemonade Punch",
    title2: "feminized auto",
    cross: "(Lemon Haze x Punchout)",

    thcLevels: "thc: 24-25%",
    yield: null,
    sativaVSindica: "50/50 Hybrid",
    flowerTime: "80 days total grow time",
    images: [
     './assets/General/seed.jpg'
    ],
  },
  {
    title: "Green Crack",
    title2: "feminized auto",

    description:
      "This Green Crack grows the most beautiful purple, with a fruity dominant taste. Works very well for something during the day, great stress reliever!",
    thcLevels: "THC: 25%",
    yield: "Average Yield -- 2oz in a 5-gallon under 100 watt LED",
    sativaVSindica: "Sativa Dominant Hybrid",
    flowerTime: "100 days seed to harvest",
    images: [
      "./assets/Green_Crack/greencrack-0.jpeg",
      "./assets/Green_Crack_Auto/greencrack-1.jpeg",
      "./assets/Green_Crack/greencrack-0.jpeg",
      "./assets/Green_Crack_Auto/greencrack-3.jpeg",
    ],
  },
  {
    title: "Purple Silverback",
    cross: "(GG#4 x Purple Ruderalis)",

    title2: "feminized auto",

    description:
      "Purple Silverback packs a HEAVY punch, great for post-op aches and pains. This strain has low maintenance growth style",
    thcLevels: "",
    yield: "Moderate Yield -- 1.5-2oz in 5-gallon under 100 watt LED",
    sativaVSindica: "",
    flowerTime: "100 days seed to harvest",
    images: [
      "./assets/Purple_Silverback/purple-silverback-5.jpeg",
      "./assets/Purple_Silverback/purple-silverback-0.jpeg",
      "./assets/Purple_Silverback/purple-silverback-3.jpeg",
      "./assets/Purple_Silverback/purple-silverback-3.jpeg",
      "./assets/Purple_Silverback/purple-silverback-4.jpeg",
      "./assets/Purple_Silverback/purple-silverback-1.jpeg",
    ],
  },
  {
    title: "Blue Dream",
    cross: null,
    flowerTime: "10 weeks flower time",
    title2: "regular photo",
    thcLevels: "thc: 20-24% 2% CBD 1% CBN",
    sativaVSindica: "Sativa dominant 60/40",
    images: ["./assets/General/seed.jpg"],
  },
  {
    title: "Girl Scout Cookies",
    cross: null,
    flowerTime: "9 weeks flower time",
    title2: "regular photo",
    thcLevels: "28% thc 1% CBD 1% CBN",
    sativaVSindica: "Indica dominant 60/40",
    images: ["./assets/General/seed.jpg"],
  },

  {
    title: "ATF",
    cross: "(ATF x Shield Maiden)",

    title2: "Feminized Photoperiod",
    description: "Creeper high with lasting relaxation effects",
    thcLevels: "thc: 19-21%",
    sativaVSindica: "70/30 Sativa",
    flowerTime: "flower time: 10 weeks",
    images: ["./assets/General/seed.jpg"],
  },
];
export const comingSoonStrains = [
  {
    title: "A.T.F II",
    cross: "(A.T.F x Shield Maiden)",

    title2: "Feminized Photoperiod",
    description:
      "Relief of Fatigue, Nausea, Migraines, Depression, ADD, ADHD, loss of appetite  ",
    thcLevels: "22-23% THC -- 15-16% CBD",
    yield: "Average Yield -- 3.5 to 4 oz in 5-gallon under 100 watt LED",
    sativaVSindica: "70/30 Sativa",
    flowerTime: "flower time: 9-10 weeks",
    images: [
      "./assets/ATF_2/ATF2-0.jpeg",
      "./assets/ATF_2/ATF2-1.jpeg",
      "./assets/ATF_2/ATF2-2.jpeg",
    ],
  },

];

export const sliderSettings = {
  arrows: false,
  slidesToShow: isMobile ? 1 : 3,
  focusOnselect: false,
  accessability: false,
};
